html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ************************* */
* {
  box-sizing: border-box;
}

body {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #000000;
}

body, html {
  max-width: 100%;
}

a {
  color: #000000;
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  border: none;
  background: none;
}

.wrapper {
  width: 100%;
  overflow: hidden;
  height: auto;
}
.wrapper::-webkit-scrollbar {
  display: none;
}
.wrapper::-webkit-scrollbar-thumb {
  display: none;
}

a.__btn-hover, .__btn-hover {
  cursor: pointer;
}
a.__btn-hover:hover, .__btn-hover:hover {
  background: #00A3F6 !important;
  background-color: #00A3F6 !important;
}

.container {
  width: 100%;
  max-width: 1260px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1260px) {
  .container {
    padding-right: 35px;
    padding-left: 35px;
  }
}
@media (max-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.header {
  position: absolute;
  top: 20px;
  width: 100%;
  left: 0;
  z-index: 2;
}
@media (max-width: 768px) {
  .header {
    top: 10px;
  }
}
.header .header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .header .header-nav__logo svg {
    width: 138px;
  }
}
.header .header-nav__menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 992px) {
  .header .header-nav__menu {
    display: none;
  }
}
.header .header-nav__menu li {
  padding-left: 45px;
  padding-right: 45px;
  color: #ffffff;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-size: 20px;
}
.header .header-nav__menu li svg {
  margin-left: 5px;
  margin-bottom: 3px;
}
.header .header-nav__menu li svg.__hover {
  display: none;
  margin-bottom: -2px;
  margin-left: 8px;
}
.header .header-nav__menu li:last-child {
  padding-right: 0;
}
.header .header-nav__menu li a {
  color: #ffffff;
}
.header .header-nav__menu li.__products-li {
  display: flex;
  align-items: center;
}
.header .header-nav__menu li ul {
  position: absolute;
  left: 0;
  top: -8px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  display: block;
  justify-content: inherit;
  padding-right: 17px;
  padding-left: 17px;
  padding-top: 44px;
  z-index: -1;
  padding-bottom: 25px;
  display: none;
}
.header .header-nav__menu li ul:before {
  content: "";
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: 141px;
  height: 1px;
  background-color: #C4C7D3;
}
.header .header-nav__menu li ul li {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
}
.header .header-nav__menu li ul li a {
  color: #000000 !important;
  font-size: 14px;
  line-height: 110%;
}
.header .header-nav__menu li ul li:hover a {
  color: #1C57D7 !important;
}
.header .header-nav__menu li:hover a {
  color: #BBF638;
}
.header .header-nav__menu li:hover svg.__default {
  display: none;
}
.header .header-nav__menu li:hover svg.__hover {
  display: block;
}
.header .header-nav__menu li:hover.__products-li > a {
  color: #1C57D7;
}
.header .header-nav__menu li:hover ul {
  display: block;
}
.header .header-nav__showmenu {
  display: none;
}
@media (max-width: 992px) {
  .header .header-nav__showmenu {
    display: block;
  }
}

.md-menu {
  background-color: #ffffff;
  padding-top: 20px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  z-index: 100;
  overflow-y: scroll;
  display: none;
}
.md-menu.__show {
  display: block;
}
.md-menu::-webkit-scrollbar {
  display: none;
}
.md-menu::-webkit-scrollbar-thumb {
  display: none;
}
.md-menu__top {
  display: flex;
  justify-content: space-between;
}
.md-menu__list {
  padding-top: 40px;
}
.md-menu__list li {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}
.md-menu__list li.md-menu__products-li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #000000;
}
.md-menu__list li.md-menu__products-li a {
  color: #000000;
}
.md-menu__list li.md-menu__products-li svg {
  margin-left: 10px;
  margin-top: 3px;
}
.md-menu__list li.md-menu__products-li svg.__active {
  display: none;
}
.md-menu__list li.md-menu__products-li ul {
  display: block;
  width: 100%;
  padding-top: 16px;
  display: none;
}
.md-menu__list li.md-menu__products-li ul.__show {
  display: block;
}
.md-menu__list li.md-menu__products-li ul li {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  color: #000000 !important;
}
.md-menu__list li.md-menu__products-li ul li a {
  color: #000000 !important;
}
.md-menu__list li.md-menu__products-li.__color {
  color: #1C57D7;
}
.md-menu__list li.md-menu__products-li.__color svg.__default {
  display: none;
}
.md-menu__list li.md-menu__products-li.__color svg.__active {
  display: block;
  transform: rotate(-180deg);
}
.md-menu__list li.md-menu__products-li.__color a {
  color: #1C57D7;
}
.md-menu__bottom {
  background: linear-gradient(117.73deg, #020B58 26.08%, #1B55D3 86.39%);
  width: 100%;
  overflow: hidden;
}
.md-menu__bottom img {
  filter: blur(5px);
  object-fit: cover;
  width: 100%;
  position: relative;
  bottom: -90px;
  right: -30px;
}

.index-main {
  background: linear-gradient(117.73deg, #020B58 26.08%, #1B55D3 86.39%);
  padding-top: 150px;
  color: #FFFFFF;
  max-height: 650px;
  height: 650px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  clip-path: ellipse(100% 100% at 50% 0);
}
@media (max-width: 768px) {
  .index-main {
    max-height: inherit;
    height: auto;
    padding-bottom: 200px;
    padding-top: 90px;
  }
}
.index-main__title {
  font-weight: 600;
  line-height: 110%;
  max-width: 716px;
  margin-bottom: 42px;
  font-size: calc(26px + 38 * (100vw - 320px) / 1600);
  display: inline-block;
}
@media (max-width: 768px) {
  .index-main__title {
    margin-bottom: 16px;
  }
}
.index-main__title span {
  position: relative;
  display: block;
}
.index-main__title br {
  display: none;
}
@media (max-width: 992px) {
  .index-main__title br {
    display: block;
  }
}
.index-main__title svg {
  width: 70px;
  position: absolute;
  right: -75px;
  bottom: 12px;
}
@media (max-width: 768px) {
  .index-main__title svg {
    width: 20px;
    bottom: -3px;
    right: -30px;
  }
}
.index-main ul li {
  font-weight: 300;
  line-height: 125%;
  margin-bottom: 16px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .index-main ul li {
    margin-bottom: 8px;
  }
}
.index-main__image {
  position: absolute;
  right: 280px;
  bottom: -90px;
  z-index: 1;
  max-width: 900px;
}
@media (max-width: 1700px) {
  .index-main__image {
    right: 0;
  }
}
@media (max-width: 768px) {
  .index-main__image {
    right: -30px;
    bottom: -70px;
  }
}
.index-products {
  color: #000000;
}
@media (max-width: 992px) {
  .index-products {
    margin-top: 60px;
  }
}
.index-products .container {
  position: relative;
}
.index-products__top {
  position: relative;
  bottom: -140px;
}
@media (max-width: 992px) {
  .index-products__top {
    bottom: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
}
.index-products__top h2 {
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 46px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .index-products__top h2 {
    margin-bottom: 0;
  }
}
.index-products__numbers {
  display: flex;
  align-items: center;
}
.index-products__number {
  margin-right: 10px;
  font-weight: 500;
  line-height: 110%;
  font-size: calc(24px + 16 * (100vw - 320px) / 1600);
  display: none;
}
.index-products__number.__length {
  color: #C4C7D3;
  display: block;
}
.index-products__number.__active {
  display: block;
}
.index-products__line {
  margin-right: 10px;
}
.index-products__slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .index-products__slide {
    flex-wrap: wrap;
  }
}
.index-products__slide .index-products__slide--left {
  padding-top: 10px;
  width: 48%;
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--left {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
  }
}
.index-products__slide .index-products__slide--left::-webkit-scrollbar {
  display: none;
}
.index-products__slide .index-products__slide--left::-webkit-scrollbar-thumb {
  display: none;
}
.index-products__slide .index-products__slide--left h3 {
  font-weight: 500;
  line-height: 110%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px;
  color: #C4C7D3;
  font-size: calc(17px + 23 * (100vw - 320px) / 1600);
  cursor: pointer;
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--left h3 {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 240px;
    margin-right: 15px;
    margin-bottom: 20px;
    text-align: center;
  }
}
.index-products__slide .index-products__slide--left h3 svg {
  cursor: pointer;
  min-width: 45px;
  display: none;
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--left h3 svg {
    width: 25px;
    min-width: 25px;
    display: block;
    transform: rotate(90deg);
    opacity: 0.5;
  }
}
.index-products__slide .index-products__slide--left h3.__active {
  color: #000000;
}
.index-products__slide .index-products__slide--left h3.__active svg {
  display: block;
  opacity: 1;
}
.index-products__slide .index-products__slide--right {
  width: 48%;
  background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
  border-radius: 30px;
  padding: 73px;
  display: none !important;
}
@media (max-width: 1260px) {
  .index-products__slide .index-products__slide--right {
    padding: 50px 20px;
  }
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--right {
    width: 100%;
    padding-top: 30px;
  }
}
.index-products__slide .index-products__slide--right .__img {
  min-height: 400px;
  height: 400px;
  max-height: 400px;
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--right .__img {
    height: auto;
    min-height: inherit;
    max-height: inherit;
  }
}
.index-products__slide .index-products__slide--right img {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  max-width: 90%;
}
@media (max-width: 992px) {
  .index-products__slide .index-products__slide--right img {
    display: block;
  }
}
.index-products__slide .index-products__slide--right p {
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 20px;
}
.index-products__slide .index-products__slide--right a {
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  color: #FFFFFF;
  background: #1C57D7;
  border-radius: 66px;
  display: flex;
  align-items: center;
  max-width: 257px;
  height: 64px;
  justify-content: center;
  margin-top: 27px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.index-products__slide .index-products__slide--right a svg {
  margin-left: 12px;
}
.index-products__slide .index-products__slide--right.__active {
  display: block !important;
}

.index-repeat {
  color: #000000;
  margin-top: 50px;
}
@media (max-width: 1260px) {
  .index-repeat {
    margin-top: 0;
  }
}
.index-repeat .container {
  position: relative;
  z-index: 1;
  padding: 50px;
}
@media (max-width: 1260px) {
  .index-repeat .container {
    padding-right: 35px;
    padding-left: 35px;
  }
}
@media (max-width: 992px) {
  .index-repeat .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 768px) {
  .index-repeat .container {
    padding-top: 20px;
  }
}
.index-repeat .container:after {
  content: "";
  width: 98.5%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, #E4F0FA 0%, rgba(237, 243, 248, 0) 100%);
  border-radius: 30px;
  z-index: -1;
}
@media (max-width: 1260px) {
  .index-repeat .container:after {
    width: 100%;
  }
}
.index-repeat__bignumber {
  font-weight: 800;
  color: #FFFFFF;
  position: absolute;
  right: 10px;
  top: -50px;
  font-size: calc(110px + 200 * (100vw - 320px) / 1600);
}
@media (max-width: 1260px) {
  .index-repeat__bignumber {
    top: -20px;
  }
}
.index-repeat__top {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.index-repeat__top img {
  max-width: 230px;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .index-repeat__top img {
    display: none;
  }
}
.index-repeat__top article h2 {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 24px;
  font-size: calc(22px + 18 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .index-repeat__top article h2 {
    display: flex;
    align-items: center;
  }
}
.index-repeat__top article h2 br {
  display: none;
}
@media (max-width: 768px) {
  .index-repeat__top article h2 br {
    display: block;
  }
}
.index-repeat__top article h2 img {
  display: none;
}
@media (max-width: 768px) {
  .index-repeat__top article h2 img {
    max-width: 127px;
    display: block !important;
    margin-right: 15px;
  }
}
@media (max-width: 768px) {
  .index-repeat__top article h2 span {
    max-width: 123px;
  }
}
.index-repeat__top article p {
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 20px;
}
.index-repeat__content {
  margin-top: 40px;
  position: relative;
  z-index: 1;
}
.index-repeat__content h3 {
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 24px;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.index-repeat__content h3 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.index-repeat__content ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 93%;
}
@media (max-width: 768px) {
  .index-repeat__content ul {
    max-width: 100%;
  }
}
.index-repeat__content ul li {
  width: 47%;
  display: flex;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 16px;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .index-repeat__content ul li {
    width: 100%;
  }
}
.index-repeat__content ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background: #25CDE3;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: 5px;
}
.index-repeat__content blockquote {
  font-size: 16px;
  line-height: 125%;
  color: #73A4C7;
  margin-top: 34px;
  max-width: 600px;
}
.index-repeat__content a {
  font-weight: 600;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  line-height: 125%;
  color: #FFFFFF;
  background: #1C57D7;
  border-radius: 66px;
  display: flex;
  align-items: center;
  max-width: 257px;
  height: 64px;
  justify-content: center;
  margin-top: 27px;
  margin-left: auto;
  position: relative;
  top: -80px;
}
@media (max-width: 992px) {
  .index-repeat__content a {
    top: inherit;
  }
}
@media (max-width: 768px) {
  .index-repeat__content a {
    margin-left: inherit;
    margin-right: auto;
  }
}
.index-repeat__content a svg {
  margin-left: 12px;
}
.index-repeat.index-repeat__second {
  margin-top: 120px;
}
@media (max-width: 992px) {
  .index-repeat.index-repeat__second {
    margin-top: 20px;
  }
}
.index-repeat.index-repeat__second .container:after {
  background: linear-gradient(180deg, #D4DCF9 0%, rgba(237, 243, 248, 0) 100%);
}
.index-repeat.index-repeat__second .index-repeat__top article {
  max-width: 705px;
}
.index-repeat.index-repeat__second .index-repeat__content ul li:before {
  background: #1C61B1;
}

.index-system {
  color: #000000;
  margin-top: 20px;
}
.index-system .container {
  position: relative;
  z-index: 1;
  padding-left: 60px;
  padding-top: 40px;
  height: 100%;
  overflow: hidden;
  border-radius: 30px;
  padding-bottom: 50px;
}
@media (max-width: 1260px) {
  .index-system .container {
    padding-top: 90px;
    overflow: inherit;
  }
}
@media (max-width: 992px) {
  .index-system .container {
    padding-top: 48px;
  }
}
@media (max-width: 768px) {
  .index-system .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.index-system__bignumber {
  font-weight: 800;
  color: #FFFFFF;
  position: absolute;
  left: 40px;
  top: -40px;
  z-index: -1;
  font-size: calc(110px + 200 * (100vw - 320px) / 1600);
}
@media (max-width: 1260px) {
  .index-system__bignumber {
    top: 15px;
  }
}
@media (max-width: 768px) {
  .index-system__bignumber {
    left: 0;
    top: -15px;
  }
}
.index-system__before {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 1230px;
  background: linear-gradient(120deg, #E9F8E3 0%, rgba(217, 245, 202, 0) 58.85%);
  border-radius: 30px;
  z-index: -2;
}
@media (max-width: 1260px) {
  .index-system__before {
    height: 100%;
    transform: rotate(0deg);
  }
}
.index-system__title {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 27px;
  max-width: 728px;
  font-size: calc(22px + 18 * (100vw - 320px) / 1600);
}
.index-system__blocks {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .index-system__blocks {
    flex-wrap: wrap;
  }
}
@media (max-width: 992px) {
  .index-system__blocks br {
    display: none;
  }
}
.index-system__left {
  width: 48%;
}
@media (max-width: 992px) {
  .index-system__left {
    width: 100%;
  }
}
.index-system__left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 20px;
}
.index-system__left h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
}
.index-system__left h3 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.index-system__left h4 {
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 16px;
}
.index-system__left ul {
  max-width: 440px;
}
.index-system__left ul li {
  display: flex;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 16px;
  align-items: flex-start;
}
.index-system__left ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background: #5EE1A2;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: 5px;
}
.index-system__left blockquote {
  font-size: 16px;
  line-height: 125%;
  color: #68A79E;
  margin-top: 34px;
}
@media (max-width: 992px) {
  .index-system__left blockquote {
    margin-bottom: 20px;
    display: none;
  }
}
.index-system__right {
  width: 48%;
}
@media (max-width: 992px) {
  .index-system__right {
    width: 100%;
  }
}
.index-system__right p {
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 20px;
}
.index-system__right h4 {
  font-size: 20px;
  line-height: 125%;
  margin-bottom: 16px;
}
@media (max-width: 992px) {
  .index-system__right ol {
    margin-bottom: 30px;
  }
}
.index-system__right ol li {
  position: relative;
  padding-left: 85px;
  display: block;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
.index-system__right ol li img {
  display: block;
  position: absolute;
  left: 0;
  top: 0%;
  max-width: 73px;
}
.index-system__right ol li strong {
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 2px;
}
.index-system__right ol li p {
  font-size: 12px;
}
.index-system__right ul {
  max-width: 440px;
}
.index-system__right ul li {
  display: flex;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 16px;
  align-items: flex-start;
}
.index-system__right ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background: #5EE1A2;
  border-radius: 100%;
  margin-right: 20px;
  margin-top: 5px;
}
.index-system__right a {
  font-weight: 600;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  line-height: 125%;
  color: #FFFFFF;
  background: #1C57D7;
  border-radius: 66px;
  display: flex;
  align-items: center;
  max-width: 257px;
  height: 64px;
  justify-content: center;
  margin-top: 27px;
  margin-left: auto;
}
@media (max-width: 768px) {
  .index-system__right a {
    margin-left: inherit;
    margin-right: auto;
  }
}
.index-system__right a svg {
  margin-left: 12px;
}
.index-system__right blockquote {
  font-size: 16px;
  line-height: 125%;
  color: #68A79E;
  margin-top: 34px;
  display: none;
}
@media (max-width: 992px) {
  .index-system__right blockquote {
    margin-bottom: 20px;
    display: block;
  }
}

.form-repeat {
  color: #000000;
}
.form-repeat__title {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 16px;
  text-align: center;
  font-size: calc(24px + 40 * (100vw - 320px) / 1600);
}
.form-repeat__minititle {
  text-align: center;
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 7px;
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .form-repeat__minititle {
    margin-bottom: 22px;
  }
}
.form-repeat__bigsvg {
  position: relative;
  bottom: -40px;
}
@media (max-width: 992px) {
  .form-repeat__bigsvg {
    height: 70px;
    bottom: -15px;
  }
}
.form-repeat form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: -40px;
  z-index: 1;
}
@media (max-width: 992px) {
  .form-repeat form {
    bottom: -15px;
  }
}
.form-repeat form input {
  font-weight: 500;
  line-height: 110%;
  border: 1px solid #C4C7D3;
  padding: 15px;
  width: 100%;
  margin-bottom: 26px;
  outline: none;
  border-radius: 5px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.form-repeat form input::placeholder {
  color: #C4C7D3;
}
.form-repeat form input:focus {
  border: 1px solid #1C57D7;
}
.form-repeat form .__checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 53px;
}
@media (max-width: 768px) {
  .form-repeat form .__checkbox {
    margin-bottom: 30px;
  }
}
.form-repeat form .__checkbox span {
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  background: #EDF3F8;
  border: 1px solid #C4C7D3;
  border-radius: 5px;
  margin-right: 20px;
}
.form-repeat form .__checkbox p {
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.form-repeat form .__checkbox p a {
  color: #00A3FF;
}
.form-repeat form .__form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1C57D7;
  border-radius: 66px;
  max-width: 381px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  height: 64px;
}
.form-repeat form .__form-btn input {
  background-color: transparent;
  color: #ffffff;
  font-weight: 700;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  border: none;
  margin-bottom: 0;
  width: auto;
  cursor: pointer;
}
.form-repeat form .__form-btn svg {
  margin-left: 10px;
}

.repeat-contacts {
  color: #FFFFFF;
  background: linear-gradient(101.83deg, #020B58 41.34%, #1B54D0 71.68%);
  padding-top: 61px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 992px) {
  .repeat-contacts {
    padding-top: 31px;
    padding-bottom: 200px;
  }
}
.repeat-contacts__bg {
  position: absolute;
  right: -240px;
  bottom: -220px;
  max-width: 970px;
}
@media (max-width: 1700px) {
  .repeat-contacts__bg {
    max-width: 700px;
    right: -160px;
    bottom: -160px;
  }
}
@media (max-width: 1260px) {
  .repeat-contacts__bg {
    max-width: 600px;
    bottom: -140px;
  }
}
@media (max-width: 992px) {
  .repeat-contacts__bg {
    right: inherit;
    left: 50%;
    transform: translateX(-50%);
    max-width: 700px;
    width: 700px;
    bottom: -200px;
  }
}
.repeat-contacts .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .repeat-contacts .container {
    flex-wrap: wrap;
  }
}
.repeat-contacts__left {
  width: 45%;
}
@media (max-width: 992px) {
  .repeat-contacts__left {
    width: 100%;
    margin-bottom: 30px;
  }
}
.repeat-contacts__left p {
  line-height: 125%;
  margin-bottom: 22px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
}
.repeat-contacts__left a {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 135%;
  margin-bottom: 10px;
  color: #FFFFFF;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
}
.repeat-contacts__left a svg {
  margin-right: 20px;
}
.repeat-contacts__right {
  width: 45%;
  padding-left: 25px;
  position: relative;
}
@media (max-width: 1700px) {
  .repeat-contacts__right {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .repeat-contacts__right {
    width: 100%;
    padding-left: 0;
    padding-top: 25px;
  }
}
.repeat-contacts__right:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 114px;
  width: 2px;
  background-color: #ffffff;
}
@media (max-width: 992px) {
  .repeat-contacts__right:before {
    width: 100%;
    height: 2px;
  }
}
.repeat-contacts__right p {
  margin-bottom: 20px;
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 1700px) {
  .repeat-contacts__right p {
    max-width: 450px;
  }
}
@media (max-width: 992px) {
  .repeat-contacts__right p {
    max-width: 100%;
  }
}
.repeat-contacts__right p strong {
  font-weight: 700;
}

.about-main {
  background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
  position: relative;
  padding-top: 140px;
  padding-bottom: 114px;
  overflow: hidden;
}
@media (max-width: 576px) {
  .about-main {
    padding-bottom: 230px;
  }
}
.about-main__svg {
  position: absolute;
  left: 30px;
  top: 0;
}
.about-main__bg {
  position: absolute;
  width: 1112px;
  height: 834px;
  right: -300px;
  bottom: -260px;
  width: calc(500px + 612 * (100vw - 320px) / 1600);
  height: calc(260px + 574 * (100vw - 320px) / 1600);
}
@media (max-width: 1260px) {
  .about-main__bg {
    right: -120px;
    bottom: -120px;
  }
}
@media (max-width: 768px) {
  .about-main__bg {
    bottom: -40px;
    right: -70px;
  }
}
@media (max-width: 576px) {
  .about-main__bg {
    right: inherit;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
  }
}
@media (max-width: 1260px) {
  .about-main .container {
    position: relative;
    z-index: 1;
  }
}
.about-main .container h1 {
  font-weight: 600;
  line-height: 110%;
  color: #FFFFFF;
  font-size: calc(16px + 20 * (100vw - 320px) / 1600);
  max-width: calc(240px + 779 * (100vw - 320px) / 1600);
}
@media (max-width: 576px) {
  .about-main .container h1 {
    max-width: 100%;
    line-height: 24px;
  }
}
.about-main .container h1 strong {
  font-weight: 600;
}
.about-main .container h1 p {
  margin-bottom: 15px;
}

.about-solutions {
  color: #000000;
  margin-top: calc(40px + 40 * (100vw - 320px) / 1600);
}
.about-solutions__title {
  font-size: 36px;
  line-height: 110%;
  margin-bottom: 39px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
}
.about-solutions__items {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .about-solutions__items {
    flex-wrap: wrap;
  }
}
.about-solutions__item {
  background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
  border-radius: 30px;
  padding: 31px;
  width: 31%;
}
@media (max-width: 992px) {
  .about-solutions__item {
    width: 100%;
  }
}
.about-solutions__item img {
  max-width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.about-solutions__item h3 {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 16px;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
.about-solutions__item p {
  font-size: 16px;
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}

.about-benefits {
  color: #000000;
  margin-top: calc(40px + 40 * (100vw - 320px) / 1600);
  margin-bottom: calc(40px + 20 * (100vw - 320px) / 1600);
}
.about-benefits__title {
  line-height: 110%;
  position: relative;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.about-benefits__title .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.about-benefits__items {
  margin-top: 68px;
}
@media (max-width: 992px) {
  .about-benefits__items {
    margin-top: 32px;
  }
}
.about-benefits__item {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .about-benefits__item {
    flex-wrap: wrap;
  }
}
.about-benefits__item h3 {
  display: flex;
  justify-content: space-between;
  width: 30%;
  font-weight: 600;
  line-height: 110%;
  color: #C4C7D3;
  margin-top: 15px;
  cursor: pointer;
  font-size: calc(20px + 20 * (100vw - 320px) / 1600);
  /*&:hover {
  	color: #000000;
  	svg {
  		display: block;
  	}
  }*/
}
@media (max-width: 992px) {
  .about-benefits__item h3 {
    width: 100%;
    color: #000000;
    margin-top: 0;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
  }
}
.about-benefits__item h3 svg {
  margin-top: 7px;
  display: none;
}
@media (max-width: 992px) {
  .about-benefits__item h3 svg {
    transform: rotate(90deg);
    width: 30px;
    margin-left: 10px;
    display: block;
  }
}
.about-benefits__item article {
  width: 67%;
  line-height: 110%;
  color: #99BCD4;
  background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
  border-radius: 30px;
  padding-left: 60px;
  margin-bottom: 26px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  padding: calc(15px + 35 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .about-benefits__item article {
    width: 100%;
    color: #000000;
  }
}
.about-benefits__item article ul li {
  margin-bottom: 15px;
  display: flex;
}
.about-benefits__item article ul li:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  width: 7px;
  min-width: 7px;
  height: 7px;
  min-height: 7px;
  background-color: #99BCD4;
  border-radius: 100%;
  margin-top: 7px;
}
.about-benefits__item.__active h3 {
  color: #000000;
}
.about-benefits__item.__active h3 svg {
  display: block;
}
.about-benefits__item.__active article {
  color: #000000;
  background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
}
.about-benefits__item.__active article ul li:before {
  background-color: #000000;
}
.about-benefits__item:hover h3 {
  color: #000000;
}
.about-benefits__item:hover h3 svg {
  display: block;
}
.about-benefits__item:hover article {
  color: #000000;
  background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
}
.about-benefits__item:hover article ul li:before {
  background-color: #000000;
}

.career-main {
  position: relative;
  padding-top: 151px;
  color: #000000;
}
@media (max-width: 768px) {
  .career-main {
    padding-top: 110px;
  }
}
.career-main:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 84px;
  left: 0;
  top: 0;
  background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
}
@media (max-width: 768px) {
  .career-main:before {
    height: 60px;
  }
}
.career-main__svg {
  position: absolute;
  left: 40px;
  top: 0;
}
.career-main h1 {
  line-height: 125%;
  margin-bottom: 56px;
  font-size: calc(16px + 20 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .career-main h1 {
    margin-bottom: 30px;
  }
}
.career-main h1 span {
  background-color: #b7fa39;
}
.career-main h1 strong {
  font-weight: 600;
}
.career-main article {
  padding: 44px;
  background: linear-gradient(180deg, #E4F0FA 0%, rgba(237, 243, 248, 0) 100%);
  border-radius: 30px;
  margin-bottom: 80px;
}
@media (max-width: 768px) {
  .career-main article {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;
  }
}
.career-main article h2 {
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 53px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  margin-bottom: 30px;
}
.career-main article ul li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 110%;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .career-main article ul li {
    margin-bottom: 20px;
  }
}
.career-main article ul li svg {
  margin-right: 25px;
}
@media (max-width: 768px) {
  .career-main article ul li svg {
    width: 20px;
    min-width: 20px;
  }
}

.spr-main {
  background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
  padding-top: 152px;
  padding-bottom: 100px;
  color: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 768px) {
  .spr-main {
    padding-bottom: 240px;
    position: relative;
    padding-top: 110px;
  }
}
.spr-main .container {
  position: relative;
  height: 100%;
}
@media (max-width: 768px) {
  .spr-main .container {
    position: static;
  }
}
.spr-main__bg {
  position: absolute;
  right: -150px;
  bottom: -250px;
  max-width: 846px;
}
@media (max-width: 1400px) {
  .spr-main__bg {
    max-width: 750px;
  }
}
@media (max-width: 992px) {
  .spr-main__bg {
    max-width: 600px;
    bottom: -200px;
    right: -100px;
  }
}
@media (max-width: 768px) {
  .spr-main__bg {
    right: inherit;
    bottom: -90px;
    left: 50%;
    max-width: 460px;
    width: 700px;
    transform: translateX(-50%);
  }
}
.spr-main__title {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 31px;
  font-size: calc(26px + 38 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .spr-main__title {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .spr-main__title br {
    display: none;
  }
}
.spr-main__descr {
  font-size: 32px;
  line-height: 125%;
  font-size: calc(16px + 16 * (100vw - 320px) / 1600);
  max-width: calc(288px + 342 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .spr-main__descr {
    max-width: 100%;
  }
}

.spr-content {
  color: #000000;
  margin-top: calc(40px + 32 * (100vw - 320px) / 1600);
  margin-bottom: calc(40px + 42 * (100vw - 320px) / 1600);
}
.spr-content .container {
  position: relative;
}
.spr-content__bignumber {
  position: absolute;
  right: -20px;
  bottom: -60px;
  font-weight: 800;
  line-height: 110%;
  color: #EEF3F8;
  font-size: calc(90px + 220 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .spr-content__bignumber {
    display: none;
  }
}
.spr-content h2 {
  line-height: 110%;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  margin-bottom: calc(16px + 24 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.spr-content h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.spr-content h2 br {
  display: none;
}
@media (max-width: 576px) {
  .spr-content h2 br {
    display: block;
  }
}
.spr-content p {
  line-height: 125%;
  margin-bottom: 20px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.spr-content__block {
  background: linear-gradient(120deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 58.85%);
  border-radius: 30px;
  padding: 23px 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
  z-index: 1;
}
@media (max-width: 992px) {
  .spr-content__block {
    flex-wrap: wrap;
    padding: 23px 15px;
    padding-bottom: 15px;
  }
}
.spr-content__block h4 {
  width: 40%;
  font-weight: 500;
  line-height: 125%;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .spr-content__block h4 {
    width: 100%;
    margin-bottom: 20px;
  }
}
.spr-content__block article {
  width: 57%;
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .spr-content__block article {
    width: 100%;
  }
}
.spr-content__block article h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
.spr-content__block article p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
}
.spr-content__block.__last {
  background: linear-gradient(80deg, #EDF3F8 0%, rgba(237, 243, 248, 0.3) 16.37%);
}

.spr-devices {
  color: #000000;
}
@media (max-width: 576px) {
  .spr-devices .container {
    padding-right: 0;
  }
}
.spr-devices h2 {
  line-height: 110%;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  align-items: flex-end;
  /*@media(max-width: $media-screen-xs) {
  	justify-content: flex-start;
  }*/
}
.spr-devices h2 .__before-line {
  display: block;
  margin-right: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .spr-devices h2 {
    padding-right: 15px;
  }
}
.spr-devices__items {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
@media (max-width: 576px) {
  .spr-devices__items {
    max-width: 100%;
    overflow-x: scroll;
    margin-top: 24px;
  }
}
.spr-devices__items::-webkit-scrollbar {
  display: none;
}
.spr-devices__items::-webkit-scrollbar-thumb {
  display: none;
}
.spr-devices__item {
  background: linear-gradient(180deg, #9CD2FF 0%, rgba(168, 215, 254, 0.64) 38.47%, rgba(237, 243, 248, 0) 92.33%);
  border-radius: 30px;
  padding: 43px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .spr-devices__item {
    padding: 22px;
  }
}
@media (max-width: 576px) {
  .spr-devices__item {
    min-width: 160px;
    margin-right: 11px;
  }
}
.spr-devices__item img {
  max-width: 170px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .spr-devices__item img {
    max-width: 100px;
  }
}
.spr-devices__item h4 {
  line-height: 110%;
  text-align: center;
  margin-bottom: 16px;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}
.spr-devices__item p {
  line-height: 110%;
  text-align: center;
  color: #C4C7D3;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}

.spr-documentation {
  margin-top: 79px;
  color: #000000;
  margin-bottom: 89px;
}
@media (max-width: 768px) {
  .spr-documentation {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.spr-documentation .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .spr-documentation .container {
    flex-wrap: wrap;
  }
}
.spr-documentation__left {
  width: 48%;
}
@media (max-width: 768px) {
  .spr-documentation__left {
    width: 100%;
    margin-bottom: 20px;
  }
}
.spr-documentation__left h2 {
  line-height: 110%;
  margin-bottom: 32px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.spr-documentation__left h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.spr-documentation__left p {
  font-weight: 500;
  font-size: 24px;
  line-height: 125%;
  color: #00A3FF;
  display: flex;
  font-size: calc(14px + 12 * (100vw - 320px) / 1600);
}
.spr-documentation__left p svg {
  margin-right: 10px;
  min-width: 70px;
}
.spr-documentation__right {
  width: 48%;
  background: linear-gradient(20deg, #EDF3F8 0%, rgba(237, 243, 248, 0.1) 96.37%);
  border-radius: 30px;
  padding: 28px 52px;
}
@media (max-width: 992px) {
  .spr-documentation__right {
    padding: 20px 15px;
  }
}
@media (max-width: 768px) {
  .spr-documentation__right {
    width: 100%;
  }
}
.spr-documentation__right h3 {
  font-size: 24px;
  line-height: 125%;
  margin-bottom: 20px;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
.spr-documentation__right p {
  line-height: 125%;
  margin-bottom: 18px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.spr-documentation__right p strong {
  font-weight: 600;
}

.msb-main {
  background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
  padding-top: 152px;
  padding-bottom: 50px;
  color: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 768px) {
  .msb-main {
    padding-bottom: 240px;
    position: relative;
    padding-top: 110px;
  }
}
.msb-main .container {
  position: relative;
  height: 100%;
}
@media (max-width: 768px) {
  .msb-main .container {
    position: static;
  }
}
.msb-main__bg {
  position: absolute;
  right: -250px;
  bottom: -200px;
  max-width: 846px;
}
@media (max-width: 1400px) {
  .msb-main__bg {
    max-width: 750px;
  }
}
@media (max-width: 992px) {
  .msb-main__bg {
    max-width: 600px;
    bottom: -200px;
    right: -100px;
  }
}
@media (max-width: 768px) {
  .msb-main__bg {
    right: inherit;
    bottom: -90px;
    left: 50%;
    max-width: 460px;
    width: 700px;
    transform: translateX(-50%);
  }
}
.msb-main__title {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 31px;
  font-size: calc(26px + 38 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .msb-main__title {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .msb-main__title br {
    display: none;
  }
}
.msb-main__descr {
  line-height: 125%;
  font-size: calc(16px + 16 * (100vw - 320px) / 1600);
  max-width: calc(288px + 342 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .msb-main__descr {
    max-width: 100%;
  }
}

.msb-content {
  color: #000000;
  margin-top: calc(40px + 32 * (100vw - 320px) / 1600);
  margin-bottom: calc(40px + 42 * (100vw - 320px) / 1600);
}
.msb-content .container {
  position: relative;
}
.msb-content__bignumber {
  position: absolute;
  right: -20px;
  bottom: -60px;
  font-weight: 800;
  font-size: 310.731px;
  line-height: 110%;
  color: #E9F9E0;
  font-size: calc(90px + 220 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .msb-content__bignumber {
    display: none;
  }
}
.msb-content h2 {
  line-height: 110%;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  margin-bottom: calc(16px + 24 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.msb-content h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.msb-content h2 br {
  display: none;
}
@media (max-width: 576px) {
  .msb-content h2 br {
    display: block;
  }
}
.msb-content p {
  line-height: 125%;
  padding-bottom: 20px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.msb-content__block {
  background: linear-gradient(120deg, rgba(167, 232, 133, 0.26) 0%, rgba(227, 243, 240, 0) 96.37%, rgba(172, 233, 140, 0) 58.85%);
  border-radius: 30px;
  padding: 23px 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
  z-index: 1;
}
@media (max-width: 992px) {
  .msb-content__block {
    flex-wrap: wrap;
    padding: 23px 15px;
    padding-bottom: 15px;
  }
}
.msb-content__block h4 {
  width: 40%;
  font-weight: 500;
  line-height: 125%;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .msb-content__block h4 {
    width: 100%;
    margin-bottom: 20px;
  }
}
.msb-content__block article {
  width: 57%;
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .msb-content__block article {
    width: 100%;
  }
}
.msb-content__block article h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
.msb-content__block article p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}
.msb-content__block.__last {
  background: linear-gradient(80deg, rgba(167, 232, 133, 0.26) 0%, rgba(227, 243, 240, 0.3) 96.37%, rgba(172, 233, 140, 0) 96.37%);
}

.msb-devices {
  color: #000000;
}
@media (max-width: 576px) {
  .msb-devices .container {
    padding-right: 0;
  }
}
.msb-devices h2 {
  line-height: 110%;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.msb-devices h2 .__before-line {
  display: block;
  margin-right: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .msb-devices h2 {
    padding-right: 15px;
  }
}
.msb-devices__items {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
@media (max-width: 576px) {
  .msb-devices__items {
    max-width: 100%;
    overflow-x: scroll;
    margin-top: 24px;
  }
}
.msb-devices__items::-webkit-scrollbar {
  display: none;
}
.msb-devices__items::-webkit-scrollbar-thumb {
  display: none;
}
.msb-devices__item {
  background: linear-gradient(180deg, #A4E880 0%, rgba(197, 237, 182, 0.552083) 46.64%, rgba(237, 243, 248, 0) 92.33%);
  border-radius: 30px;
  padding: 43px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .msb-devices__item {
    padding: 22px;
  }
}
@media (max-width: 576px) {
  .msb-devices__item {
    min-width: 160px;
    margin-right: 11px;
  }
}
.msb-devices__item img {
  max-width: 170px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .msb-devices__item img {
    max-width: 100px;
  }
}
.msb-devices__item h4 {
  line-height: 110%;
  text-align: center;
  margin-bottom: 16px;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}
.msb-devices__item p {
  line-height: 110%;
  text-align: center;
  color: #C4C7D3;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}

.msb-documentation {
  margin-top: 79px;
  color: #000000;
  margin-bottom: 89px;
}
@media (max-width: 768px) {
  .msb-documentation {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.msb-documentation .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .msb-documentation .container {
    flex-wrap: wrap;
  }
}
.msb-documentation__left {
  width: 48%;
}
@media (max-width: 768px) {
  .msb-documentation__left {
    width: 100%;
    margin-bottom: 20px;
  }
}
.msb-documentation__left h2 {
  line-height: 110%;
  margin-bottom: 32px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.msb-documentation__left h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.msb-documentation__left p {
  font-weight: 500;
  line-height: 125%;
  color: #00A3FF;
  display: flex;
  font-size: calc(14px + 12 * (100vw - 320px) / 1600);
}
.msb-documentation__left p svg {
  margin-right: 10px;
  min-width: 70px;
}
.msb-documentation__right {
  width: 48%;
  background: linear-gradient(180deg, rgba(233, 249, 225, 0.63) 0%, #e8f9e0 96.37%);
  border-radius: 30px;
  padding: 28px 52px;
}
@media (max-width: 992px) {
  .msb-documentation__right {
    padding: 20px 15px;
  }
}
@media (max-width: 768px) {
  .msb-documentation__right {
    width: 100%;
  }
}
.msb-documentation__right h3 {
  line-height: 125%;
  margin-bottom: 20px;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
.msb-documentation__right p {
  line-height: 125%;
  margin-bottom: 18px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.msb-documentation__right p strong {
  font-weight: 600;
}

.crm-main {
  background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
  padding-top: 152px;
  padding-bottom: 50px;
  color: #FFFFFF;
  overflow: hidden;
}
@media (max-width: 768px) {
  .crm-main {
    padding-bottom: 240px;
    position: relative;
    padding-top: 110px;
  }
}
.crm-main .container {
  position: relative;
  height: 100%;
}
@media (max-width: 768px) {
  .crm-main .container {
    position: static;
  }
}
.crm-main__bg {
  position: absolute;
  right: -250px;
  bottom: -200px;
  max-width: 846px;
}
@media (max-width: 1400px) {
  .crm-main__bg {
    max-width: 750px;
  }
}
@media (max-width: 992px) {
  .crm-main__bg {
    max-width: 600px;
    bottom: -200px;
    right: -100px;
  }
}
@media (max-width: 768px) {
  .crm-main__bg {
    right: inherit;
    bottom: -90px;
    left: 50%;
    max-width: 460px;
    width: 700px;
    transform: translateX(-50%);
  }
}
.crm-main__title {
  font-weight: 500;
  line-height: 110%;
  margin-bottom: 31px;
  font-size: calc(26px + 38 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .crm-main__title {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .crm-main__title br {
    display: none;
  }
}
.crm-main__descr {
  line-height: 125%;
  font-size: calc(16px + 16 * (100vw - 320px) / 1600);
  max-width: calc(288px + 442 * (100vw - 320px) / 1600);
}
@media (max-width: 768px) {
  .crm-main__descr {
    max-width: 100%;
  }
}

.crm-content {
  color: #000000;
  margin-top: calc(40px + 32 * (100vw - 320px) / 1600);
  margin-bottom: calc(40px + 42 * (100vw - 320px) / 1600);
}
.crm-content .container {
  position: relative;
}
.crm-content__bignumber {
  position: absolute;
  right: -20px;
  bottom: -60px;
  font-weight: 800;
  line-height: 110%;
  color: #F1F4FD;
  font-size: calc(90px + 220 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .crm-content__bignumber {
    display: none;
  }
}
.crm-content h2 {
  line-height: 110%;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  margin-bottom: calc(16px + 24 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.crm-content h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.crm-content h2 br {
  display: none;
}
@media (max-width: 576px) {
  .crm-content h2 br {
    display: block;
  }
}
.crm-content p {
  line-height: 125%;
  padding-bottom: 20px;
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.crm-content__block {
  background: linear-gradient(120deg, #EBEFFF 0%, rgba(214, 222, 249, 0) 58.85%);
  border-radius: 30px;
  padding: 23px 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: relative;
  z-index: 1;
}
@media (max-width: 992px) {
  .crm-content__block {
    flex-wrap: wrap;
    padding: 23px 15px;
    padding-bottom: 15px;
  }
}
.crm-content__block h4 {
  width: 40%;
  font-weight: 500;
  line-height: 125%;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .crm-content__block h4 {
    width: 100%;
    margin-bottom: 20px;
  }
}
.crm-content__block article {
  width: 57%;
  line-height: 125%;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 992px) {
  .crm-content__block article {
    width: 100%;
  }
}
.crm-content__block article h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
.crm-content__block article p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
}
.crm-content__block.__last {
  background: linear-gradient(80deg, #EBEFFF 0%, rgba(214, 222, 249, 0.1) 96.37%);
}

.crm-devices {
  color: #000000;
}
@media (max-width: 576px) {
  .crm-devices .container {
    padding-right: 0;
  }
}
.crm-devices h2 {
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  line-height: 110%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.crm-devices h2 .__before-line {
  display: block;
  margin-right: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
@media (max-width: 576px) {
  .crm-devices h2 {
    padding-right: 15px;
  }
}
.crm-devices__items {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
@media (max-width: 576px) {
  .crm-devices__items {
    max-width: 100%;
    overflow-x: scroll;
    margin-top: 24px;
  }
}
.crm-devices__items::-webkit-scrollbar {
  display: none;
}
.crm-devices__items::-webkit-scrollbar-thumb {
  display: none;
}
.crm-devices__item {
  background: linear-gradient(180deg, #B4C4FF 0%, rgba(200, 212, 255, 0.53) 49.05%, rgba(237, 243, 248, 0) 92.33%);
  border-radius: 30px;
  padding: 43px;
  width: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .crm-devices__item {
    padding: 22px;
  }
}
@media (max-width: 576px) {
  .crm-devices__item {
    min-width: 160px;
    margin-right: 11px;
  }
}
.crm-devices__item img {
  max-width: 170px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .crm-devices__item img {
    max-width: 100px;
  }
}
.crm-devices__item h4 {
  line-height: 110%;
  text-align: center;
  margin-bottom: 16px;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}
.crm-devices__item p {
  line-height: 110%;
  text-align: center;
  color: #C4C7D3;
  font-size: calc(12px + 12 * (100vw - 320px) / 1600);
}

.crm-documentation {
  margin-top: 79px;
  color: #000000;
  margin-bottom: 89px;
}
@media (max-width: 768px) {
  .crm-documentation {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.crm-documentation .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .crm-documentation .container {
    flex-wrap: wrap;
  }
}
.crm-documentation__left {
  width: 48%;
}
@media (max-width: 768px) {
  .crm-documentation__left {
    width: 100%;
    margin-bottom: 20px;
  }
}
.crm-documentation__left h2 {
  line-height: 110%;
  margin-bottom: 32px;
  font-size: calc(20px + 16 * (100vw - 320px) / 1600);
  display: flex;
  align-items: flex-end;
}
.crm-documentation__left h2 .__after-line {
  display: block;
  margin-left: 5px;
  height: 2px;
  background-color: #000000;
  flex-grow: 1;
  margin-bottom: 5px;
}
.crm-documentation__left p {
  font-weight: 500;
  line-height: 125%;
  color: #00A3FF;
  display: flex;
  font-size: calc(14px + 12 * (100vw - 320px) / 1600);
}
.crm-documentation__left p svg {
  margin-right: 10px;
  min-width: 70px;
}
.crm-documentation__right {
  width: 48%;
  background: linear-gradient(80deg, #EBEFFF 0%, rgba(214, 222, 249, 0.1) 96.37%);
  border-radius: 30px;
  padding: 28px 52px;
}
@media (max-width: 992px) {
  .crm-documentation__right {
    padding: 20px 15px;
  }
}
@media (max-width: 768px) {
  .crm-documentation__right {
    width: 100%;
  }
}
.crm-documentation__right h3 {
  line-height: 125%;
  margin-bottom: 20px;
  font-size: calc(20px + 4 * (100vw - 320px) / 1600);
}
.crm-documentation__right p {
  line-height: 125%;
  margin-bottom: 18px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.crm-documentation__right p strong {
  font-weight: 600;
}

.footer {
  padding: 50px;
  background: linear-gradient(101.83deg, #020B58 41.34%, #1B54D0 71.68%);
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .footer {
    padding: 26px;
  }
}
.footer .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer .container span {
  font-weight: 300;
  color: #b3b3b3;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  margin-top: 10px;
}
.footer.footer__order {
  background: #ffffff;
}
.footer.footer__order .container span {
  color: #4d4d4d;
}