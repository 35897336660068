.repeat-contacts {
	color: #FFFFFF;
	background: linear-gradient(101.83deg, #020B58 41.34%, #1B54D0 71.68%);
	padding-top: 61px;
	padding-bottom: 60px;
	position: relative;
	overflow: hidden;
	@media(max-width: $media-screen-md) {
		padding-top: 31px;
		padding-bottom: 200px;
	}
	&__bg {
		position: absolute;
		right: -240px;
		bottom: -220px;
		max-width: 970px;
		@media(max-width: 1700px) {
			max-width: 700px;
			right: -160px;
			bottom: -160px;
		}
		@media(max-width: $media-screen-lg) {
			max-width: 600px;
			bottom: -140px;
		}
		@media(max-width: $media-screen-md) {
			right: inherit;
			left: 50%;
			transform: translateX(-50%);
			max-width: 700px;
			width: 700px;
			bottom: -200px;
		}
	}
	.container {
		display: flex;
		justify-content: space-between;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
		}
	}
	&__left {
		width: 45%;
		@media(max-width: $media-screen-md) {
			width: 100%;
			margin-bottom: 30px;
		}
		p {
			line-height: 125%;
			margin-bottom: 22px;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
		}

		a {
			display: flex;
			align-items: center;
			font-weight: 600;
			line-height: 135%;
			margin-bottom: 10px;
			color: #FFFFFF;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
			svg {
				margin-right: 20px;
			}

		}
	}
	&__right {
		width: 45%;
		padding-left: 25px;
		position: relative;
		@media(max-width: 1700px) {
			width: 50%;
		}
		@media(max-width: $media-screen-md) {
			width: 100%;
			padding-left: 0;
			padding-top: 25px;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 114px;
			width: 2px;
			background-color: #ffffff;
			@media(max-width: $media-screen-md) {
				width: 100%;
				height: 2px;
			}
		}
		p {
			margin-bottom: 20px;
			line-height: 125%;
			font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: 1700px) {
				max-width: 450px;
			}
			@media(max-width: $media-screen-md) {
				max-width: 100%;
			}
			strong {
				font-weight: 700;
			}
		}
	}
}