.md-menu {
	background-color: #ffffff;
	padding-top: 20px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	z-index: 100;
	overflow-y: scroll;
	display: none;
	&.__show {
		display: block;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		display: none;
	}
	&__top {
		display: flex;
		justify-content: space-between;
	}
	&__list {
		padding-top: 40px;
		li {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 30px;
		}

		li.md-menu__products-li {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #000000;
			a {
				color: #000000;
			}
			svg {
				margin-left: 10px;
				margin-top: 3px;
				&.__active {
					display: none;
				}
			}

			ul {
				display: block;
				width: 100%;
				padding-top: 16px;
				display: none;
				&.__show {
					display: block;
				}
				li {
					font-size: 20px;
					line-height: 28px;
					margin-bottom: 16px;
					color: #000000 !important;
					a {
						color: #000000 !important;
					}
				}
			}

			&:hover {
				svg {
					//transform: rotate(-180deg);
				}
			}

			&.__color {
				color: #1C57D7;
				svg {
					&.__default {
						display: none;
					}

					&.__active {
						display: block;
						transform: rotate(-180deg);
					}
				}
				a {
					color: #1C57D7;
				}
			}
		}

	}


	&__bottom {
		background: linear-gradient(117.73deg, #020B58 26.08%, #1B55D3 86.39%);
		width: 100%;
		overflow: hidden;
		img {
			filter: blur(5px);
			object-fit: cover;
			width: 100%;
			position: relative;
			bottom: -90px;
			right: -30px;
		}
	}
}