.career {}

.career-main {
	position: relative;
	padding-top: 151px;
	color: #000000;
	@media(max-width: $media-screen-sm) {
		padding-top: 110px;
	}
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 84px;
		left: 0;
		top: 0;
		background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
		@media(max-width: $media-screen-sm) {
			height: 60px;
		}
	} 

	&__svg {
		position: absolute;
		left: 40px;
		top: 0;
	}

	h1 {
		line-height: 125%;
		margin-bottom: 56px;
		font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-sm) {
			margin-bottom: 30px;
		}
		span {
			background-color: #b7fa39;
		}
		strong {
			font-weight: 600;
		}
	}

	article {
		padding: 44px;
		background: linear-gradient(180deg, #E4F0FA 0%, rgba(237, 243, 248, 0) 100%);
		border-radius: 30px;
		margin-bottom: 80px;
		@media(max-width: $media-screen-sm) {
			padding-top: 22px;
			padding-bottom: 22px;
			padding-right: 15px;
			padding-left: 15px;
			margin-bottom: 30px;
		}
		h2 {
			font-weight: 600;
			line-height: 110%;
			margin-bottom: 53px;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
			margin-bottom: 30px;
		}

		ul {
			li {
				display: flex;
				align-items: center;
				margin-bottom: 30px;
				font-weight: 500;
				line-height: 110%;
				font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
				@media(max-width: $media-screen-sm) {
					margin-bottom: 20px;
				}
				svg {
					margin-right: 25px;
					@media(max-width: $media-screen-sm) {
						width: 20px;
						min-width: 20px;
					}
				}
			}
		}
	}
}