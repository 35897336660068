.index-main {
	background: linear-gradient(117.73deg, #020B58 26.08%, #1B55D3 86.39%);
	padding-top: 150px;
	color: #FFFFFF;
	max-height: 650px;
	height: 650px;
	overflow: hidden;
	position: relative;
	z-index: 1;
	clip-path: ellipse(100% 100% at 50% 0);
	@media(max-width: $media-screen-sm) {
		max-height: inherit;
		height: auto;
		padding-bottom: 200px;
		padding-top: 90px;
	}
	&__title {
		font-weight: 600;
		line-height: 110%;
		max-width: 716px;
		margin-bottom: 42px;
		font-size: calc(26px + (64 - 26) * ((100vw - 320px) / (1920 - 320)));
		display: inline-block;
		@media(max-width: $media-screen-sm) {
			margin-bottom: 16px;
		}
		span {
			position: relative;
			display: block;
		}
		br {
			display: none;
			@media(max-width: $media-screen-md) {
				display: block;
			}
		}
		svg {
			width: 70px;
			position: absolute;
			right: -75px;
			bottom: 12px;
			@media(max-width: $media-screen-sm) {
				width: 20px;
				bottom: -3px;
				right: -30px;
			}
		}
	}

	ul {
		li {
			font-weight: 300;
			line-height: 125%;
			margin-bottom: 16px;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-sm) {
				margin-bottom: 8px;
			}
		}
	}

	&__image {
		position: absolute;
		right: 280px;
		bottom: -90px;
		z-index: 1;
		max-width: 900px;
		@media(max-width: 1700px) {
			right: 0;
		}
		@media(max-width: $media-screen-sm) {
			right: -30px;
			bottom: -70px;
		}
		img {
			
		}
	}
}
