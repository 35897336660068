.form-repeat {
	color: #000000;
	&__title {
		font-weight: 500;
		line-height: 110%;
		margin-bottom: 16px;
		text-align: center;
		font-size: calc(24px + (64 - 24) * ((100vw - 320px) / (1920 - 320)));
	}
	&__minititle {
		text-align: center;
		font-weight: 500;
		line-height: 110%;
		margin-bottom: 7px;
		font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-md) {
			margin-bottom: 22px;
		}
	}

	&__bigsvg {
		position: relative;
		bottom: -40px;
		@media(max-width: $media-screen-md) {
			height: 70px;
			bottom: -15px;
		}
	}

	form {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		bottom: -40px;
		z-index: 1;
		@media(max-width: $media-screen-md) {
			bottom: -15px;
		}
		input {
			font-weight: 500;
			line-height: 110%;
			border: 1px solid #C4C7D3;
			padding: 15px;
			width: 100%;
			margin-bottom: 26px;
			outline: none;
			border-radius: 5px;
			font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
			&::placeholder {
				color: #C4C7D3;
			}
			&:focus {
				border: 1px solid  #1C57D7;
			}
		}
		.__checkbox {
			display: flex;
			align-items: center;
			margin-bottom: 53px;
			@media(max-width: $media-screen-sm) {
				margin-bottom: 30px;
			}
			span {
				display: block;
				width: 32px;
				min-width: 32px;
				height: 32px;
				min-height: 32px;
				background: #EDF3F8;
				border: 1px solid #C4C7D3;
				border-radius: 5px;
				margin-right: 20px;
			}

			p {
				line-height: 125%;
				font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
				a {
					color: #00A3FF;
				}
			}
		}

		.__form-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #1C57D7;
			border-radius: 66px;
			max-width: 381px;
			margin-left: auto;
			margin-right: auto;
			cursor: pointer;
			height: 64px;
			input {
				background-color: transparent;
				color: #ffffff;
				font-weight: 700;
				font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
				border: none;
				margin-bottom: 0;
				width: auto;
				cursor: pointer;
			}
			svg {
				margin-left: 10px;
			}
		}
	}
}