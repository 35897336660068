.index-system {
	color: #000000;
	margin-top: 20px;
	.container {
		position: relative;
		z-index: 1;
		padding-left: 60px;
		padding-top: 40px;
		height: 100%;
		overflow: hidden;
		border-radius: 30px;
		padding-bottom: 50px;
		@media(max-width: $media-screen-lg) {
			padding-top: 90px;
			overflow: inherit;
		}
		@media(max-width: $media-screen-md) {
			padding-top: 48px;
		}
		@media(max-width: $media-screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__bignumber {
		font-weight: 800;
		color: #FFFFFF;
		position: absolute;
		left: 40px;
		top: -40px;
		z-index: -1;
		font-size: calc(110px + (310 - 110) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-lg) {
			top: 15px;
		}
		@media(max-width: $media-screen-sm) {
			left: 0;
			top: -15px;
		}
	}

	&__before {
		position: absolute;
		left: 0;
		top: 0px;
		width: 100%;
		height: 1230px;
		background: linear-gradient(120deg, #E9F8E3 0%, rgba(217, 245, 202, 0) 58.85%);
		border-radius: 30px;
		//border: 1px solid red;
		z-index: -2;
		//transform: rotate(-90deg);
		@media(max-width: $media-screen-lg) {
			height: 100%;
			transform: rotate(0deg);
		}
		//transform: rotate(-90deg);
	}

	&__title {
		font-weight: 500;
		line-height: 110%;
		margin-bottom: 27px;
		max-width: 728px;
		font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
	}
	&__blocks {
		display: flex;
		justify-content: space-between;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
		}
		br {
			@media(max-width: $media-screen-md) {
				display: none;
			}
		}
	}
	&__left {
		width: 48%;
		@media(max-width: $media-screen-md) {
			width: 100%;
		}
		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 125%;
			margin-bottom: 20px;
		}

		h3 {
			font-weight: 600;
			font-size: 24px;
			line-height: 125%;
			margin-bottom: 24px;
			display: flex;
			align-items: flex-end;
			.__after-line {
				display: block;
				margin-left: 5px;
				height: 2px;
				background-color: #000000;
				flex-grow: 1;
				margin-bottom: 5px;
			}
		}

		h4 {
			font-size: 20px;
			line-height: 125%;
			margin-bottom: 16px;
		}

		ul {
			max-width: 440px;
			li {
				display: flex;
				font-size: 16px;
				line-height: 125%;
				margin-bottom: 16px;
				align-items: flex-start;
				&:before {
					content: '';
					//display: inline-block;
					width: 10px;
					height: 10px;
					min-width: 10px;
					min-height: 10px;
					background: #5EE1A2;
					border-radius: 100%;
					margin-right: 20px;
					margin-top: 5px;
				}
			}
		}

		blockquote {
			font-size: 16px;
			line-height: 125%;
			color: #68A79E;
			margin-top: 34px;
			@media(max-width: $media-screen-md) {
				margin-bottom: 20px;
				display: none;
			}
		}
	}
	&__right {
		width: 48%;
		@media(max-width: $media-screen-md) {
			width: 100%;
		}
		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 125%;
			margin-bottom: 20px;
		}
		h4 {
			font-size: 20px;
			line-height: 125%;
			margin-bottom: 16px;
		}

		ol {
			@media(max-width: $media-screen-md) {
				margin-bottom: 30px;
			}
			li {
				position: relative;
				padding-left: 85px;
				display: block;
				min-height: 70px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 20px;
				img {
					display: block;
					position: absolute;
					left: 0;
					top: 0%;
					max-width: 73px;
				}
				strong {
					font-size: 16px;
					font-weight: 600;
					display: block;
					margin-bottom: 2px;
				}

				p {
					font-size: 12px;
				}
			}
		}

		ul {
			max-width: 440px;
			li {
				display: flex;
				font-size: 16px;
				line-height: 125%;
				margin-bottom: 16px;
				align-items: flex-start;
				&:before {
					content: '';
					//display: inline-block;
					width: 10px;
					height: 10px;
					min-width: 10px;
					min-height: 10px;
					background: #5EE1A2;
					border-radius: 100%;
					margin-right: 20px;
					margin-top: 5px;
				}
			}
		}

		a {
			font-weight: 600;
			font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
			line-height: 125%;
			color: #FFFFFF;
			background: #1C57D7;
			border-radius: 66px;
			display: flex;
			align-items: center;
			max-width: 257px;
			height: 64px;
			justify-content: center;
			margin-top: 27px;
			margin-left: auto;
			@media(max-width: $media-screen-sm) {
				margin-left: inherit;
				margin-right: auto;
			}
			svg {
				margin-left: 12px;
			}
		}

		blockquote {
			font-size: 16px;
			line-height: 125%;
			color: #68A79E;
			margin-top: 34px;
			display: none;
			@media(max-width: $media-screen-md) {
				margin-bottom: 20px;
				display: block;
			}
		}
	}

}