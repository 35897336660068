.index-products {
	color: #000000;
	@media(max-width: $media-screen-md) {
		margin-top: 60px;
	}
	.container {
		position: relative;
	}
	&__top {
		position: relative;
		bottom: -140px;
		@media(max-width: $media-screen-md) {
			bottom: inherit;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 40px;
		}
		h2 {
			font-weight: 400;
			line-height: 110%;
			margin-bottom: 46px;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-md) {
				margin-bottom: 0;
			}
		}
	}
	&__numbers {
		display: flex;
		align-items: center;
	}
	&__number {
		margin-right: 10px;
		font-weight: 500;
		line-height: 110%;
		font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
		display: none;
		&.__length {
			color: #C4C7D3;
			display: block;
		}
		&.__active {
			display: block;
		}
	}
	&__line {
		margin-right: 10px;
	}
	&__slides {}
	&__slide {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
		}
		.index-products__slide--left {
			padding-top: 10px;
			width: 48%;
			@media(max-width: $media-screen-md) {
				width: 100%;
				max-width: 100%;
				overflow-x: scroll;
				display: flex;
			}	
			&::-webkit-scrollbar {
				display: none;
			}
			&::-webkit-scrollbar-thumb {
				display: none;
			}
			h3 {
				font-weight: 500;
				line-height: 110%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 54px;
				color: #C4C7D3;
				font-size: calc(17px + (40 - 17) * ((100vw - 320px) / (1920 - 320)));
				cursor: pointer;
				@media(max-width: $media-screen-md) {
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					min-width: 240px;
					margin-right: 15px;
					margin-bottom: 20px;
					text-align: center;
				}
				svg {
					cursor: pointer;
					min-width: 45px;
					display: none;
					@media(max-width: $media-screen-md) {
						width: 25px;
						min-width: 25px;
						display: block;
						transform: rotate(90deg);
						opacity: 0.5;
						//margin-left: 20px;
						//margin-left: auto;
						//margin-right: auto;
					}
				}
				&.__active {
					color: #000000;
					svg {
						display: block;
						opacity: 1;
					}
				}
			}
		}
		.index-products__slide--right {
			width: 48%;
			background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
			border-radius: 30px;
			padding: 73px;
			display: none !important;
			@media(max-width: $media-screen-lg) {
				padding: 50px 20px;
			}
			@media(max-width: $media-screen-md) {
				width: 100%;
				padding-top: 30px;
			}
			.__img {
				min-height: 400px;
				height: 400px;
				max-height: 400px;
				//border: 1px solid red;
				@media(max-width: $media-screen-md) {
					height: auto;
					min-height: inherit;
					max-height: inherit;
				}
			}
			img {
				max-width: 450px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 15px;
				max-width: 90%;
				@media(max-width: $media-screen-md) {
					display: block;
				}
			}
			p {
				font-weight: 400;
				font-size: 16px;
				line-height: 125%;
				margin-bottom: 20px;
			}

			a {
				font-weight: 600;
				font-size: 24px;
				line-height: 125%;
				color: #FFFFFF;
				background: #1C57D7;
				border-radius: 66px;
				display: flex;
				align-items: center;
				max-width: 257px;
				height: 64px;
				justify-content: center;
				margin-top: 27px;
				font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
				svg {
					margin-left: 12px;
				}
			}

			&.__active {
				display: block !important;
			}
		}
	}

}