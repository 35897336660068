@import "libs/reset";
@import "fonts-and-vars";
/* ************************* */


* {
	box-sizing: border-box;
}


body {
	font-family: 'Manrope', sans-serif;
	font-weight: 400;
	color: $black;
}

body, html {
	max-width: 100%;
}

a {
	color: $black;
	text-decoration: none;
}

img {
	max-width: 100%;
}

button {
	border: none;
	background: none;
}


.wrapper {
	width: 100%;
	overflow: hidden;
	height: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		display: none;
	}
}

a.__btn-hover,  .__btn-hover{
	cursor: pointer;
	&:hover {
		background: #00A3F6 !important;
		background-color: #00A3F6 !important;
	}
}

//font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
.container {
	width: 100%;
	max-width: 1260px;
	padding-right: 15px;
	padding-left: 15px;
	margin-left: auto;
	margin-right: auto;
	@media(max-width: $media-screen-lg) {
		padding-right: 35px;
		padding-left: 35px;
	}
	@media(max-width: $media-screen-sm) {
		padding-right: 15px;
		padding-left: 15px;
	}
}


@import 'modules/header';
@import 'modules/md-menu';

@import 'index/index-main';
@import 'index/index-products';
@import 'index/index-repeat';
@import 'index/index-system';
@import 'index/form-repeat';
@import 'index/repeat-contacts';

@import 'pages/about';
@import 'pages/career';
@import 'pages/spr';
@import 'pages/msb';
@import 'pages/crm';

@import 'modules/footer';