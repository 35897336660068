//Определяем переменные
$white: #ffffff;
$black: #000000;
$media-screen-big:  1400px;
$media-screen-lg: 1260px;
$media-screen-md: 992px;
$media-screen-sm: 768px;
$media-screen-xs: 576px;

@media(max-width: $media-screen-big) {}
@media(max-width: $media-screen-lg) {}
@media(max-width: $media-screen-md) {}
@media(max-width: $media-screen-sm) {}
@media(max-width: $media-screen-xs) {}
//font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)));