.spr {}

.spr-main {
	background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
	padding-top: 152px;
	padding-bottom: 100px;
	color: #FFFFFF;
	overflow: hidden;
	@media(max-width: $media-screen-sm) {
		padding-bottom: 240px;
		position: relative;
		padding-top: 110px;
	}
	.container {
		position: relative;
		height: 100%;
		@media(max-width: $media-screen-sm) {
			position: static;
		}
	}
	&__bg {
		position: absolute;
		right: -150px;
		bottom: -250px;
		max-width: 846px;
		@media(max-width: 1400px) {
			max-width: 750px;
		}
		@media(max-width: $media-screen-md) {
			max-width: 600px;
			bottom: -200px;
			right: -100px;
		}
		@media(max-width: $media-screen-sm) {
			right: inherit;
			bottom: -90px;
			left: 50%;
			max-width: 460px;
			width: 700px;
			transform: translateX(-50%);
		}
		img {
			
		}
	}
	&__title {
		font-weight: 500;
		line-height: 110%;
		margin-bottom: 31px;
		font-size: calc(26px + (64 - 26) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-sm) {
			margin-bottom: 16px;
		}
		br {
			@media(max-width: $media-screen-sm) {
				display: none;
			}
		}
	}
	&__descr {
		font-size: 32px;
		line-height: 125%;
		font-size: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320)));
		max-width: calc(288px + (630 - 288) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-sm) {
			max-width: 100%;
		}
	}
}


.spr-content {
	color: #000000;
	margin-top: calc(40px + (72 - 40) * ((100vw - 320px) / (1920 - 320)));
	margin-bottom: calc(40px + (82 - 40) * ((100vw - 320px) / (1920 - 320)));
	.container {
		position: relative;
	}
	&__bignumber {
		position: absolute;
		right: -20px;
		bottom: -60px;
		font-weight: 800;
		line-height: 110%;
		color: #EEF3F8;
		font-size: calc(90px + (310 - 90) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-md) {
			display: none;
		}
	}
	h2 {
		line-height: 110%;
		font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
		margin-bottom: calc(16px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
		display: flex;
		align-items: flex-end;
		.__after-line {
			display: block;
			margin-left: 5px;
			height: 2px;
			background-color: #000000;
			flex-grow: 1;
			margin-bottom: 5px;
		}
		br {
			display: none;
			@media(max-width: $media-screen-xs) {
				display: block;
			}
		}
	}
	p {
		line-height: 125%;
		margin-bottom: 20px;
		font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
	}

	&__block {
		//background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0.3) 96.37%);

		background: linear-gradient(120deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 58.85%);
		border-radius: 30px;
		padding: 23px 30px;
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
		position: relative;
		z-index: 1;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
			padding: 23px 15px;
			padding-bottom: 15px;
		}
		h4 {
			width: 40%;
			font-weight: 500;
			line-height: 125%;
			font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-md) {
				width: 100%;
				margin-bottom: 20px;
			}
		}
		article {
			width: 57%;
			line-height: 125%;
			font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-md) {
				width: 100%;
			}
			h5 {
				font-weight: 600;
				margin-bottom: 15px;
			}
			p {
				font-weight: 400;
				font-size: 16px;
				margin-bottom: 15px;
			}
		}

		&.__last {
			background: linear-gradient(80deg, #EDF3F8 0%, rgba(237, 243, 248, 0.3) 16.37%);
		}
	}
}


.spr-devices {
	color: #000000;
	.container {
		@media(max-width: $media-screen-xs) {
			padding-right: 0;
		}
	}
	h2 {
		line-height: 110%;
		font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		display: flex;
		align-items: flex-end;
		.__before-line {
			display: block;
			margin-right: 5px;
			height: 2px;
			background-color: #000000;
			flex-grow: 1;
			margin-bottom: 5px;
		}
		/*@media(max-width: $media-screen-xs) {
			justify-content: flex-start;
		}*/
		@media(max-width: $media-screen-xs) {
			padding-right: 15px;
		}
	}

	&__items {
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
		@media(max-width: $media-screen-xs) {
			max-width: 100%;
			overflow-x: scroll;
			margin-top: 24px;
		}
		&::-webkit-scrollbar {
			display: none;
		}
		&::-webkit-scrollbar-thumb {
			display: none;
		}
	}
	&__item {
		background: linear-gradient(180deg, #9CD2FF 0%, rgba(168, 215, 254, 0.64) 38.47%, rgba(237, 243, 248, 0) 92.33%);
		border-radius: 30px;
		padding: 43px;
		width: 31%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		@media(max-width: $media-screen-sm) {
			padding: 22px;
		}
		@media(max-width: $media-screen-xs) {
			min-width: 160px;
			margin-right: 11px;
		}
		img {
			max-width: 170px;
			margin-bottom: 10px;
			@media(max-width: $media-screen-xs) {
				max-width: 100px;
			}
		}
		h4 {
			line-height: 110%;
			text-align: center;
			margin-bottom: 16px;
			font-size: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
		}
		p {
			line-height: 110%;
			text-align: center;
			color: #C4C7D3;
			font-size: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
		}
	}
}


.spr-documentation {
	margin-top: 79px;
	color: #000000;
	margin-bottom: 89px;
	@media(max-width: $media-screen-sm) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.container {
		display: flex;
		justify-content: space-between;
		@media(max-width: $media-screen-sm) {
			flex-wrap: wrap;
		}
	}
	&__left {
		width: 48%;
		@media(max-width: $media-screen-sm) {
			width: 100%;
			margin-bottom: 20px;
		}
		h2 {
			line-height: 110%;
			margin-bottom: 32px;
			font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
			display: flex;
			align-items: flex-end;
			.__after-line {
				display: block;
				margin-left: 5px;
				height: 2px;
				background-color: #000000;
				flex-grow: 1;
				margin-bottom: 5px;
			}
		}
		p {
			font-weight: 500;
			font-size: 24px;
			line-height: 125%;
			color: #00A3FF;
			display: flex;
			font-size: calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));
			svg {
				margin-right: 10px;
				min-width: 70px;
			}
		}
	}
	&__right {
		width: 48%;
		background: linear-gradient(20deg, #EDF3F8 0%, rgba(237, 243, 248, 0.1) 96.37%);
		border-radius: 30px;
		padding: 28px 52px;
		@media(max-width: $media-screen-md) {
			padding: 20px 15px;
		}
		@media(max-width: $media-screen-sm) {
			width: 100%;
		}
		h3 {
			font-size: 24px;
			line-height: 125%;
			margin-bottom: 20px;
			font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
		}
		p {
			line-height: 125%;
			margin-bottom: 18px;
			font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
			strong {
				font-weight: 600;
			}
		}
	}
}