.footer {
	padding: 50px;
	background: linear-gradient(101.83deg, #020B58 41.34%, #1B54D0 71.68%);
	position: relative;
	z-index: 1;
	@media(max-width: $media-screen-sm) {
		padding: 26px;
	}
	.container {
		display: flex;
		align-items: center;
		flex-direction: column;

		span {
			font-weight: 300;
			color: darken(#ffffff, 30%);
			font-size: 16px;
			text-transform: uppercase;
			display: block;
			margin-top: 10px;
		}
	}
	&.footer__order {
		background: #ffffff;

		.container {
			span {
				color: lighten(#000000, 30%);
			}
		}
	}
}