.header {
	position: absolute;
	top: 20px;
	width: 100%;
	left: 0;
	z-index: 2;
	@media(max-width: $media-screen-sm) {
		top: 10px;
	}
	.header-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__logo {
			@media(max-width: $media-screen-sm) {
				svg {
					width: 138px;
				}
			}
		}
		&__menu {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@media(max-width: $media-screen-md) {
				display: none;
			}	
			li {
				padding-left: 45px;
				padding-right: 45px;
				color: #ffffff;
				position: relative;
				z-index: 1;
				cursor: pointer;
				font-size: 20px;
				svg {
					margin-left: 5px;
					margin-bottom: 3px;
				}
				svg.__default {
					//transform: rotate(-180deg);
				}
				svg.__hover {
					display: none;
					margin-bottom: -2px;
					margin-left: 8px;
				}
				&:last-child {
					padding-right: 0;
				}
				a {
					color: #ffffff;
				}

				&.__products-li {
					display: flex;
					align-items: center;
					& > a {
						//color: #BBF638; margin-right: 5px; font-weight: 700;
					}
				}

				ul {
					position: absolute;
					left: 0;
					top: -8px;
					width: 100%;
					background-color: #ffffff;
					border-radius: 5px;
					display: block;
					justify-content: inherit;
					padding-right: 17px;
					padding-left: 17px;
					padding-top: 44px;
					z-index: -1;
					padding-bottom: 25px;
					display: none;
					&:before {
						content: '';
						position: absolute;
						top: 44px;
						left: 50%;
						transform: translateX(-50%);
						width: 141px;
						height: 1px;
						background-color: #C4C7D3;
					}
					li {
						padding-top: 10px;
						padding-bottom: 10px;
						padding-right: 0;
						padding-left: 0;
						&.__active {
							a {
								//color: #1C57D7 !important;
							}
						}
						a {
							color: #000000 !important;
							font-size: 14px;
							line-height: 110%;
						}

						&:hover {
							a {
								color: #1C57D7 !important;
							}
						}
					}
				}

				&:hover {
					a {
						color: #BBF638;
					}
					svg.__default {
						display: none;
					}
					svg.__hover {
						display: block;
					}

					&.__products-li {
						& > a {
							color: #1C57D7;
						}
					}

					ul {
						display: block;
					}
				}
			}
		}

		&__showmenu {
			display: none;
			@media(max-width: $media-screen-md) {
				display: block;
			}
		}
	}
}