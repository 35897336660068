.about {}

.about-main {
	background: linear-gradient(101.55deg, #020B58 25.93%, #1C57D7 97.31%);
	position: relative;
	padding-top: 140px;
	padding-bottom: 114px;
	overflow: hidden;
	@media(max-width: $media-screen-xs) {
		padding-bottom: 230px;
	}
	&__svg {
		position: absolute;
		left: 30px;
		top: 0;
	}
	&__bg {
		position: absolute;
		width: 1112px;
		height: 834px;
		right: -300px;
		bottom: -260px;
		width: calc(500px + (1112 - 500) * ((100vw - 320px) / (1920 - 320)));
		height: calc(260px + (834 - 260) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-lg) {
			right: -120px;
			bottom: -120px;
		}

		@media(max-width: $media-screen-sm) {
			bottom: -40px;
			right: -70px;
		}
		@media(max-width: $media-screen-xs) {
			right: inherit;
			left: 50%;
			transform: translateX(-50%);
			bottom: -20px;
		}
	}
	.container {
		@media(max-width: $media-screen-lg) {
			position: relative;
			z-index: 1;
		}
		h1 {
			font-weight: 600;
			line-height: 110%;
			color: #FFFFFF;
			font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
			max-width: calc(240px + (1019 - 240) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-xs) {
				max-width: 100%;
				line-height: 24px;
			}
			strong {
				font-weight: 600;
			}
			p {
				margin-bottom: 15px;
			}
		}
	}
}


.about-solutions {
	color: #000000;
	margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
	&__title {
		font-size: 36px;
		line-height: 110%;
		margin-bottom: 39px;
		font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
	}
	&__items {
		display: flex;
		justify-content: space-between;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
		}
	}
	&__item {
		background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
		border-radius: 30px;
		padding: 31px;
		width: 31%;
		@media(max-width: $media-screen-md) {
			width: 100%;
		}
		img {
			max-width: 80%;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
		h3 {
			font-weight: 500;
			line-height: 110%;
			margin-bottom: 16px;
			font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
		}

		p {
			font-size: 16px;
			line-height: 125%;
			font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
		}
	}
}


.about-benefits {
	color: #000000;
	margin-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
	margin-bottom: calc(40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)));
	&__title {
		line-height: 110%;
		position: relative;
		font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
		display: flex;
		align-items: flex-end;
		.__after-line {
			display: block;
			margin-left: 5px;
			height: 2px;
			background-color: #000000;
			flex-grow: 1;
			margin-bottom: 5px;
		}
	}

	&__items {
		margin-top: 68px;
		@media(max-width: $media-screen-md) {
			margin-top: 32px;
		}
	}
	&__item {
		display: flex;
		justify-content: space-between;
		@media(max-width: $media-screen-md) {
			flex-wrap: wrap;
		}
		h3 {
			display: flex;
			justify-content: space-between;
			width: 30%;
			font-weight: 600;
			line-height: 110%;
			color: #C4C7D3;
			margin-top: 15px;
			cursor: pointer;
			font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-md) {
				width: 100%;
				color: #000000;
				margin-top: 0;
				margin-bottom: 20px;
				justify-content: flex-start;
				align-items: center;
			}
			svg {
				margin-top: 7px;
				display: none;
				@media(max-width: $media-screen-md) {
					transform: rotate(90deg);
					width: 30px;
					margin-left: 10px;
					display: block;
				}
			}
			/*&:hover {
				color: #000000;
				svg {
					display: block;
				}
			}*/
		}

		article {
			width: 67%;
			line-height: 110%;
			color: #99BCD4;
			background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
			border-radius: 30px;
			padding-left: 60px;
			margin-bottom: 26px;
			font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
			padding: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
			@media(max-width: $media-screen-md) {
				width: 100%;
				color: #000000;
			}
			ul {
				li {
					margin-bottom: 15px;
					display: flex;
					&:before {
						content: '';
						display: inline-block;
						margin-right: 10px;
						width: 7px;
						min-width: 7px;
						height: 7px;
						min-height: 7px;
						background-color: #99BCD4;
						border-radius: 100%;
						margin-top: 7px;
					}
				}
			}
		}

		&.__active {
			h3 {
				color: #000000;
				svg {
					display: block;
				}
			}

			article {
				color: #000000;
				background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
				ul {
					li {
						&:before {
							background-color: #000000;
						}
					}
				}
			}
		}

		&:hover {
			h3 {
				color: #000000;
				svg {
					display: block;
				}
			}

			article {
				color: #000000;
				background: linear-gradient(180deg, #EDF3F8 0%, rgba(237, 243, 248, 0) 96.37%);
				ul {
					li {
						&:before {
							background-color: #000000;
						}
					}
				}
			}
		}
	}
}