.index-repeat {
	color: #000000;
	margin-top: 50px;
	@media(max-width: $media-screen-lg) {
		margin-top: 0;
	}
	.container {
		position: relative;
		z-index: 1;
		padding: 50px;
		@media(max-width: $media-screen-lg) {
			padding-right: 35px;
			padding-left: 35px;
		}
		@media(max-width: $media-screen-md) {
			padding-left: 15px;
			padding-right: 15px;
		}
		@media(max-width: $media-screen-sm) {
			padding-top: 20px;
		}
		&:after {
			content: '';
			width: 98.5%;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			background: linear-gradient(180deg, #E4F0FA 0%, rgba(237, 243, 248, 0) 100%);
			border-radius: 30px;
			z-index: -1;
			@media(max-width: $media-screen-lg) {
				width: 100%;
			}
		}
	}

	&__bignumber {
		font-weight: 800;
		//font-size: 310.731px;
		color: #FFFFFF;
		position: absolute;
		right: 10px;
		top: -50px;
		font-size: calc(110px + (310 - 110) * ((100vw - 320px) / (1920 - 320)));
		@media(max-width: $media-screen-lg) {
			top: -20px;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;
		//border: 1px solid red;
		img {
			max-width: 230px;
			margin-right: 30px;
			@media(max-width: $media-screen-sm) {
				display: none;
			}
		}
		article {
			h2 {
				font-weight: 500;
				line-height: 110%;
				margin-bottom: 24px;
				font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
				@media(max-width: $media-screen-sm) {
					display: flex;
					align-items: center;
				}

				br {
					display: none;
					@media(max-width: $media-screen-sm) {
						display: block;
					}
				}

				img {
					display: none;
					@media(max-width: $media-screen-sm) {
						max-width: 127px;
						display: block !important;
						margin-right: 15px;
					}
				}
				span {
					@media(max-width: $media-screen-sm) {
						max-width: 123px;
					}
				}
			}
			p {
				font-size: 16px;
				line-height: 125%;
				margin-bottom: 20px;
			}
		}
	}

	&__content {
		margin-top: 40px;
		position: relative;
		z-index: 1;
		h3 {
			font-weight: 600;
			line-height: 125%;
			margin-bottom: 24px;
			font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
			display: flex;
			align-items: flex-end;
			.__after-line {
				display: block;
				margin-left: 5px;
				height: 2px;
				background-color: #000000;
				flex-grow: 1;
				margin-bottom: 5px;
			}
		}
		ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			max-width: 93%;
			@media(max-width: $media-screen-sm) {
				max-width: 100%;
			}
			li {
				width: 47%;
				display: flex;
				font-size: 16px;
				line-height: 125%;
				margin-bottom: 16px;
				align-items: flex-start;
				@media(max-width: $media-screen-sm) {
					width: 100%;
				}
				&:before {
					content: '';
					width: 10px;
					height: 10px;
					min-width: 10px;
					min-height: 10px;
					background: #25CDE3;
					border-radius: 100%;
					margin-right: 20px;
					margin-top: 5px;
				}
			}
		}

		blockquote {
			font-size: 16px;
			line-height: 125%;
			color: #73A4C7;
			margin-top: 34px;
			max-width: 600px;
		}

		a {
			font-weight: 600;
			font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
			line-height: 125%;
			color: #FFFFFF;
			background: #1C57D7;
			border-radius: 66px;
			display: flex;
			align-items: center;
			max-width: 257px;
			height: 64px;
			justify-content: center;
			margin-top: 27px;
			margin-left: auto;
			position: relative;
			top: -80px;
			@media(max-width: $media-screen-md) {
				top: inherit;
			}
			@media(max-width: $media-screen-sm) {
				margin-left: inherit;
				margin-right: auto;
			}
			svg {
				margin-left: 12px;
			}
		}
	}

	&.index-repeat__second {
		margin-top: 120px;
		@media(max-width: $media-screen-md) {
			margin-top: 20px;
		}
		.container {
			&:after {
				background: linear-gradient(180deg, #D4DCF9 0%, rgba(237, 243, 248, 0) 100%);
			}
		}

		.index-repeat__top {
			article {
				max-width: 705px;
			}
		}

		.index-repeat__content {
			ul {
				li {
					&:before {
						background: #1C61B1;
					}
				}
			}
		}
	}
}